import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid, GridRowsProp } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { getListImg } from '_helpers';
import { FilterItemsConsumerHook } from '_store/filters';
import { useItems } from './use-items';
import { Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type ItemsProps = {
	brewery: number;
	type: number;
	overrides: string;
	search: string;
	history: any;
};
const Items = (props: ItemsProps) => {
	const navigate = useNavigate();
	const [filters, updFilters] = FilterItemsConsumerHook();
	const { data: fetchItem, isLoading } = useItems(props);
	const [paginationModel, setPaginationModel] = React.useState({
		page: Number(filters?.page ?? 0),
		pageSize: 50,
	});

	useEffect(() => {
		updFilters({
			type: 'page',
			value: paginationModel.page,
		});
	}, [paginationModel]);

	function goToDetail(id) {
		navigate('/items/detail/' + id);
	}

	const mystyle = {
		width: '80px',
	};

	return (
		<>
			{fetchItem?.data && (
				<DataGrid
					autoHeight
					rows={fetchItem?.data ? (fetchItem?.data as GridRowsProp) : []}
					columns={[
						{
							field: 'images',
							headerName: 'Image',
							width: 100,
							renderCell: (params) => {
								if (params.row && params.row.image) {
									return (
										<img
											style={mystyle}
											src={getListImg(params.row.image.path)}
										/>
									);
								}
								return <>n/a</>;
							},
						},
						{
							field: 'title',
							headerName: 'Titre',
							flex: 0.8,
							renderCell: (params) => {
								return (
									<div>
										<div className='line-height-1'>
											<Typography variant='caption' component='div'>
												{params.row.title}
											</Typography>
											<Link href={'/items/detail/' + params.row.id}>
												<small>Détail</small>
											</Link>
										</div>
									</div>
								);
							},
						},
						{ field: 'created', headerName: 'Date', flex: 0.2 },
					]}
					paginationModel={paginationModel}
					paginationMode='server'
					onPaginationModelChange={setPaginationModel}
					loading={isLoading}
					onCellClick={(params) => {
						goToDetail(params.row.id);
					}}
					sortModel={
						props.search
							? []
							: [
									{
										field: 'created',
										sort: 'desc',
									},
							  ]
					}
				/>
			)}
		</>
	);
};

export default Items;
