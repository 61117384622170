import { Author } from '../pages/book/authors/use-authors';

export const enum ValidationState {
	UNDEFINED = 'UNDEFINED',
	ACCEPTED = 'ACCEPTED',
	REFUSED = 'REFUSED',
}
export const enum EditionValidationType {
	TITLE = 'TITLE',
	PRICE = 'PRICE',
	EDITOR = 'EDITOR',
	DATE = 'DATE',
	LANG = 'LANG',
	IMAGE = 'IMAGE',
	ISBN = 'ISBN',
	USR = 'USR',
	WEIGHT = 'WEIGHT',
	SIZE = 'SIZE',
	PAGE = 'PAGE',
}
export const enum SerieItemType {
	STANDARD = 'STANDARD',
	HORSSERIE = 'HORSSERIE',
	INTEGRAL = 'INTEGRAL',
}

export interface SearchResponse<T> {
	objects: T[];
	nextOffset: number;
	nextPage: number;
	count: number;
}

export interface EditorDto {
	id: number;
	name: string;
	link: string;
}

export interface EditionsAdminDto {
	ean: string;
	isbn: string[];
	title: string;
	image: ImagesDto;
	date: string;
	editor: EditorDto;
	price: number;
	item: ItemDto;
}

export interface SeriesDto {
	id: number;
	name: string;
	status: string;
	count: number;
	userId: number;
	userState: ValidationState;
	groupId: number;
	image?: ImagesDto;
}

export interface SeriesGroupDto {
	id: number;
	name: string;
	series: SeriesDto[];
}

export interface SerieItemAdminDto {
	userId: number;
	userState: ValidationState;
	item: ItemDto;
	serie?: SeriesDto;
	user?: User;
	type: SerieItemType;
	pos: number;
}
export interface SerieVariant {
	id: number;
	userId: number;
	name: string;
	link: string;
	lastCheck: string;
	frequency: number;
}

export interface EditionMedia {
	ean: string;
	title: string;
	isbn: string[];
	price: number;
	editor?: EditorDto;
	image?: ImagesDto;
	date?: string;
	lang?: string;
	weight?: number;
	size?: string;
	page?: number;
}

export interface SerieMedia {
	obj: SeriesDto;
	itemsIds?: number[];
	items: ItemDto[];
	count: number;
}
export interface EditionUserMedia {
	ean: string;
	isbn: string[];
	isbnState: ValidationState;
	title: string;
	titleState: ValidationState;
	price: number;
	priceState: ValidationState;
	editor?: EditorDto;
	editorState: ValidationState;
	comment: string;
	lang: string;
	langState: ValidationState;
	date: string;
	dateState: ValidationState;
	stateId: number;
	userId: number;
	userIdState: ValidationState;
	rate: number;
	image: ImagesDto;
	imageState: ValidationState;
	weight?: number;
	weightState: ValidationState;
	size?: string;
	sizeState: ValidationState;
	page?: number;
	pageState: ValidationState;
}
export enum StatesConstant {
	UNDEFINED = 0,
	AVAILABLE = 1,
	WANTED = 3,
	WANT_TO_BORROW = 7,
	READ = 6,
	SELL = 5,
}

export interface ItemListUserDto {
	id: number;
	state: StatesConstant;
	rate?: number;
	read: boolean;
}
export interface ItemListDto {
	id: number;
	title: string;
	lang?: string;
	created: string;
	type: number;
	pos?: number;
	computed?: MediaComputed;
	user?: ItemListUserDto;
	image?: ImagesDto;
	editions?: EditionUserMedia[];
}

export interface MediaComputed {
	lastRefreshDate?: string;
	customeData1?: string;
}
export interface AuthorItemListDto extends ItemListDto {
	authorId: number;
	authorName: string;
	roleId: number;
	roleName: string;
	usrId: number;
	userState: ValidationState;
}

export interface ItemDto extends Omit<ItemListDto, 'image'> {
	user?: ItemListUserDto;
	asin?: string;
	desc?: string;
	lang?: string;
	images?: ImagesDto[];
	serieCheck?: string;
	series?: SerieMedia[];
	otherLanguage?: ItemListDto[];
	priceNew?: number;
	weight?: number;
	source?: SourceMedia[];
	authors?: AuthorMedia[];
}

export interface ImagesDto {
	id: number;
	path: string;
	height: number;
	width: number;
	hashPerceptual: string;
}

export interface ItemUserImagesDto {
	itemId: number;
	usrId: number;
	image: ImagesDto;
	refused: boolean;
}

export interface User {
	id: number;
	name: string;
	email: string;
	avatar_id: string;
}

export interface SearchResponse<T> {
	objects: T[];
	keyword: string;
}

export interface SourceMedia {
	id: number;
	srcId: number;
	url: string;
	ean?: string;
	isMain?: boolean;
	format?: string;
	lastRefresh?: string;
	editionsLastRefresh?: string;
}

export interface AuthorMedia {
	authorId: number;
	authorName: string;
	authorLastname: string;
	authorSurname: string;
	roleId: number;
	roleName: string;
}

export interface AuthorRole {
	id: number;
	name: string;
}

export const NewsSource = {
	ACTUABD: 'ACTUABD',
	BUBBLE: 'BUBBLE',
	FIGARO: 'FIGARO',
	FRANCEINFO: 'FRANCEINFO',
	BDGEST: 'BDGEST',
} as const;

export type NewsDto = {
	id: number;
	title: string;
	desc: string;
	date: Date;
	source: keyof typeof NewsSource;
	link: string;
	image: ImagesDto;
};
export type NewsUserDto = {
	id: number;
	newsId: number;
	userId: number;
	see: Date;
};
export type NewsDetailDto = {
	news: NewsDto;
	newsUser: NewsUserDto;
};
export type NewsLinkDto = {
	series: SeriesDto[];
	authors: Author[];
	items: ItemListDto[];
};
export type NewsDetailLinkDto = {
	news: NewsDto;
	newsUser: NewsUserDto;
	links: NewsLinkDto;
};

export type NewsLinkAdminDto = {
	id: number;
	title: string;
	linkSource: string;
	validationState: ValidationState;
};
